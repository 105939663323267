import React from 'react'
import banner from '../Images/Warehousebanner.png';
import Reader from '../Images/Products/FX9600.png';
import Printer from '../Images/Products/ZT600R.png';
import Antenna from '../Images/Products/AN440.png';
import Inlay1 from '../Images/Products/9662.png';
import WMS from '../Images/Solution/Solution_Warhouse.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Warehouse = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Warehouse</li>
            </ul>
          </nav>
          </div>
   
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR WAREHOUSE</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Warehouse:</h3>
                <p className='mt-3'>RSPM digital warehouse management system adopts advanced RFID technology, which can guarantee the accuracy of warehouse management and real-time updating of data.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Warehouse:</h3>
                <p className='mt-3' >RSPM hardware solution for warehouse management, integrates RFID equipment and labels into conventional warehouse operations. The new system enables companies to improve workflow efficiency by automatically collecting data in the warehouse work processes such as inbound, outbound, inventory, etc.</p>
                
                <img src ={WMS} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Warehouse:</h3>
                <ul className='mt-3'>
                  <li>Simplify the work process.</li>
                  <li>Improve the levels of efficiency and data transparency.</li>
                  <li>Meet the demands of enterprise informatization, intelligence, and modernization.</li>
                  <li>Reduce the inventory cycle and acquire inventory status in real time.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Printer} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Reader} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
