import React from 'react'
import Footer from './Footer'
import Slide from './Images/Abtslide.jpg';
import {ImHome} from 'react-icons/im'
import Partners from './Partners';
import ScrollButton from './Home/Scrollbutton';
import logo1 from './Images/Logo1.png';
const About = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
   
      <div className='container'>
        <div className="mb-0">
            <img src ={Slide} alt="About-slide" className='img-fluid'/> 
            <div className='d-flex p-1'>
              <ImHome className='m-1'/>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
                  <li className="breadcrumb-item active" aria-current="page">About</li>
                </ol>
              </nav>
            </div>
        </div>  
        <br/>
      </div>

      <div className='container bg-light p-2'>
        
        <p className='p-5'><img src ={logo1} alt="Logo1" className='abt-image p-2 mt-3 img-fluid'  />
        <h1 className='abtpage-title fw-bold my-5 '>RSPM: The Global Leading RFID And IOT Solution Provider</h1>
        <p> RSPM INFOTECH., a leading IT Enabled Service provider, system integrator and Solution providing company especially in the field of Barcode, RFID, BIO-Metric Solution, Access Control system, Industrial & Retail Automation Solution and Mobility Solution to across all industries and business verticals.</p>
        <p>RSPM Infotech design, develop and implement customized application software solution for RFID automation, Label Printing solution, Gate Entry System, Visitor Management System, Vehicle management System, Biometric Solution and many other specific solution requirements and Quality Assurance is an essential part of any project, which ensures the top quality of our solutions.</p>
        <p>RSPM Infotech has a unique position as a one-stop-shop RFID company and supplier able to deliver a full range of products which includes tags, antennas, fixed and handheld readers, and system integration software. RSPM underlines its commitment to the RFID market supplying a complete RFID product line enabling the best-in-class RFID system integrators and value added resellers to provide state of the art market solutions.</p>
        <p>Quality Assurance is an essential part of any project, which ensures the top quality of our solutions. This is a complicated process, which takes place at all other stages. We help clients enhance customer engagements, increase predictability and maximize revenue across the value chain. As a best tech support service company, we are trying to break the sterotypes in service industry through focused adoption and implementation of digital that would help our clients build better customer intimacy and non-linear revenue models. We constantly work with our customers in TMT, Retail and CPG Sectors to understand their emerging business problems and help them simplify their engagement models by embedding resilience into business processes.</p>
        <br/>
        </p>
      </div>
      <div className='container p-2'>
        <div className='row row-cols-1 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 '>
              <div className='col p-5 justify-content-center'>
                <div class="about-card card border-primary mb-3">
                  <div class="card-body ">
                    <h1 class="card-title text-center text-primary">Mission</h1>
                    <div className='my-4'>
                      <p >We are committed to the highest standards of ethics and integrity. Since the future of our company depends squarely on knowledge, creativity, skill, collaboration, and good integrity of our staff.</p>
                      <p>We are committed to providing premier service and support to our clients. We must do this through innovation and by continually improving efficiency.</p>
                      <p>We are dedicated to quality—quality of products, quality of service, quality of relationships, quality of communications, and quality of our promises.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col p-5 justify-content-center'>
                <div class="about-card card border-primary mb-3" >
                  <div class="card-body ">
                    <h1 class="card-title text-center text-primary">Vision</h1>
                    <div className='my-4'>
                    <p>We manage by our business values to provide a safe, productive, and fulfilling work environment for employees, as well as legendary services for customers. enhanced value for shareholders, and mutually beneficial outcomes for all stakeholders.</p>
                    <p>We are dedicated to long-term stability and strategic growth.</p>
                    <p>Our vision is to be the premier provider of products that yield the highest value to our customers</p>
                    </div>
                  </div>
                
                </div>
              </div>
        </div>
      </div>
    
    <ScrollButton/>
    <Partners/>
    <Footer/>
    </>
  )
}

export default About