import React from 'react'
import banner from '../Images/Banner/Biometric_Reader.jpg';
import {ImHome} from 'react-icons/im';
import Bio from '../Images/Products/UFace402.png';
import Bio1 from '../Images/Products/Speedface.png';
import Bio2 from '../Images/Products/K40PRO.png';
import Bio3 from '../Images/Products/IN02-A.png';
import Bio4 from '../Images/Products/F19.png';
import Bio5 from '../Images/Products/X7.png';
import Footer from '../Footer';
import FX9600 from '../Documents/FX9600-specification.pdf';
import FX7500 from '../Documents/FX7500-specification.pdf';
import RFD8500 from '../Documents/RFD8500-Specification.pdf';
import ART7000 from '../Documents/ART7000-Specification.pdf';

import ScrollButton from '../Home/Scrollbutton';

const Biometric = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className='container'>
      <div className="mb-0 bg-light">
          <img src ={banner} alt="RFID-slide" className='img-fluid'/> 
          <div className='d-flex p-1'>
            <ImHome className='m-1'/>
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
                <li className="breadcrumb-item ms-1"> <a href="/product">Products</a></li>
                <li className="breadcrumb-item active" aria-current="page">Biometric Readers</li>
              </ul>
            </nav>
          </div>
    
      </div>
    </div>
    <div className='container'>
      <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF RFID BIOMETRIC READER</h1>
        <h5 className='mt-4'>Searching for The Right Biometric Reader</h5>
      </div>
    </div> 
      <div className='container'>
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-3">
            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">UFace 402</h5>
                  <img src={Bio} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={FX9600} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">SpeedFace V5L</h5>
                  <img src={Bio1} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={FX7500} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">K40 Pro</h5>
                  <img src={Bio2} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={RFD8500} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">IN02-A</h5>
                  <img src={Bio3} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ART7000} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          
            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">F19</h5>
                  <img src={Bio4} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ART7000} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">X7</h5>
                  <img src={Bio5} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ART7000} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div> 
      </div>  
        <br/>
        <br/>
        <br/>
        <div className='container'>
      <div className='why-bg img-fluid'>
        <h1 className=' container d-inline-block text-center text-white fw-bold pt-5'>WHY CHOOSE RSPM BIOMETRIC</h1>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5 ">
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>01</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Globally applying</h3>
                 <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card mt-5'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>02</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                 <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>03</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                 <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    <br/>  
        
      <ScrollButton/>
      <Footer/>
    </>
  )
}

export default Biometric