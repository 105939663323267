import React from 'react'
import banner from '../Images/Smart-Attendance.jpg';
import handled from '../Images/Products/RFD40.png';
import Printer from '../Images/Products/ZT600R.png';
import Antenna from '../Images/Products/AN440.png';
import Inlay1 from '../Images/Products/9662.png';
import Animalsol from '../Images/Solution/Solution_Cylinder.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Cylinder = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
     
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Cylinder Tracking</li>
            </ul>
          </nav>
          </div>
       
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR CYLINDER TRACKING</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Cylinder Tracking:</h3>
                <p className='mt-3'>RPSM Cylinder Tracking System adopts advanced RFID Technology which is helpful for tracking cylinder, whether in transit or in warehouses, allows businesses to automatically monitor the movement of cylinders and data collection process is also automated, allowing businesses to receive accurate updates in real-time straight to an asset tracking system.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Cylinder Tracking:</h3>
                <p className='mt-3'>RSPM RFID Cylinder Management Solution provides automated asset monitoring for gas cylinders in use throughout the Oil and Gas industry. Our system allows you to track cylinders with higher accuracy than was previously possible.</p>
                
                <img src ={Animalsol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Cylinder Tracking:</h3>
                <ul className='mt-3'>
                    <li>Accurate information with less effort & lower labor costs.</li>
                    <li>Cylinders can be tracked on the dock, at a customer site, at fill plants, at testing facilities, or at other distributors.</li>
                    <li>Warehouse cylinder stocks can be counted in a fraction of seconds.</li>
                    <li>Real time visibility of stocks & location of cylinders.</li>
                    <li>Simplify the process of delivery process by saving time, reducing errors and enhances the customer satisfaction.</li>
                    <li>Decrease in lost cylinders or cylinders credited to the wrong account. Greater efficiency in inventory used and ordered.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Printer} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={handled} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
