import React from 'react'
import Footer from '../Footer'
import Slider from './Slider'
import logo1 from '../Images/Logo2.png';
import '../card.css';
import { Button } from 'react-bootstrap';
import {HiArrowCircleRight} from 'react-icons/hi';
import map from '../Images/worldmap.png'
import ScrollButton from './Scrollbutton';
import Catalogues from './Catalogues.js';
import rfidworks from '../Images/rfidworks.jpg';
import Confidential from '../Images/Confidentiality.jpg';
import Integrity from '../Images/Integrity.jpg';
import Availability from '../Images/Availability.jpg';
import {FaTools} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
function Home() {
  let navigate = useNavigate(); 
  const about = () =>{ 
  let path = `/about`; 
  navigate(path);
}

  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  
  return (
    <>
    
      <Slider/>

      <div className='container p-5'>
              <div className='text-center'><img src ={logo1} alt="Logo1"  className="home-logo img-fluid "/>
                <h1 className='home-head text-uppercase fw-bold '>One-stop-shop Rfid Service Provider</h1>
                <br></br>
              
          <p>RSPM INFOTECH., a leading IT Enabled Service provider, system integrator and Solution providing company especially in the field of Barcode, RFID, BIO-Metric Solution, Access Control system, Industrial & Retail Automation Solution and Mobility Solution to across all industries and business verticals.</p>
          <p>RSPM Infotech has a unique position as a one-stop-shop RFID company and supplier able to deliver a full range of products which includes tags, antennas, fixed and handheld readers, and system integration software. RSPM underlines its commitment to the RFID market supplying a complete RFID product line enabling the best-in-class RFID system integrators and value added resellers to provide state of the art market solutions.</p>
          </div>
      </div>
   
      <div className='container'>
      <div className='row row-cols-1 row-cols-lg-2 row-cols-md-2 row-cols-sm-1'>
         <div className='col  '>
            <img src={rfidworks} alt="" className='img-fluid rfidworks p-2'/>
          </div>
          <div className='col'>
          <h1 className='rfidworks-head pb-4'>HOW DOES RFID WORKS?</h1>
          <div className='how-rfidworks'>
            <p className='pb-2 text-justify'><FaTools className='rfidworks-icon  me-2'/><span>Component:</span> Reader, Antenna and Tag.</p>
            <p className='pb-2 text-justify'><FaTools className='rfidworks-icon me-2'/><span>Working principal:</span> Reader emits radio waves to transmit signal and activate tags. Tags will immediately send a wave back to antennas and convert radio wave into data which save in the tag.</p>
            <p className='pb-2 text-justify '><FaTools className='rfidworks-icon me-2'/><span>Working procedure:</span> Tags are scanned and read by reader, and antenna translates radio frequency into data form. Once raw data have been processed by software system, ideal data infromation will display on the interface as anticipated.</p>
          </div>
        </div>
      </div>
      <h2 className='cat-head my-5'> RSPM RFID & Barcode Catalogue</h2> 
      </div>
     <Catalogues/>
     
      <br/>
      <br/>
      <div className='container'>
      <div className=' row row-cols-1 row-cols-lg-2 row-cols-md-2 row-cols-sm-1'>
          <div className='col'>
            <div className='d-flex mx-5'>
              <span className='line my-2'></span><div className='home-abt-head '><p>RSPM The Global Leading RFID And IoT Solution Provider</p></div>
            </div>
            <h1 className="abt-head fw-bold m-2">
            <img src ={logo1} alt="Logo1" width="20%" height="70%" className="img-fluid"/>About RSPM Infotech
            </h1>
            <div className='container'>
              <p>RSPM INFOTECH., a leading IT Enabled Service provider, system integrator and Solution providing company especially in the field of Barcode, RFID, BIO-Metric Solution, Access Control system, Industrial & Retail Automation Solution and Mobility Solution to across all industries and business verticals.</p>
              <p>RSPM Infotech has a unique position as a one-stop-shop RFID company and supplier able to deliver a full range of products which includes tags, antennas, fixed and handheld readers, and system integration software. RSPM underlines its commitment to the RFID market supplying a complete RFID product line enabling the best-in-class RFID system integrators and value added resellers to provide state of the art market solutions.</p>
              <div className= 'text-center p-3'>
                  <Button className='abt-btn shadow-lg d-flex btn btn-Primary rounded-pill p-2 m-1 ' onClick= {about} style={{ width: 'Auto-content' }}>
                  <div className='ms-2'>To Know RSPM More</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
                  </Button>
              </div>
            </div> 
          </div>
        
      </div>
      </div>
      <br/>
      <div className='container py-5 bg-light'>
      <h2 className='Guarante-privacy text-bold text-uppercase text-center'>RSPM RFID: GUARANTEE YOUR PRIVACY SECURITY</h2>
      <div className='container'>
      <div className='Guarante-privacy-main  row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 '>
          <div className='col'>
            <img src={Confidential} alt='Confidentiality' className='img-fluid pt-5'/>
            <div className='box'>
              <div className='container'>
                  <h3 className=' fw-bold'>CONFIDENTIALITY:</h3>
                  <div className='d-flex'>
                    <p className=' text-wrap py-2'>Data saved in tag are protected by particular encryption algorithm.</p>
                    <div className='num fw-bold '>01</div>
                  </div>
              </div>
             </div>
          </div>
          <div className='col'>
            <img src={Integrity} alt='Integrity' className='img-fluid'/>
            <div className='box'>
              <div className='container'>
                  <h3 className='fw-bold'>INTEGRITY:</h3>
                  <div className='d-flex'>
                    <p className='text-wrap py-2'>Data can't be modified or deleted without authorizing.</p>
                    <div className='num fw-bold '>02</div>
                  </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <img src={Availability} alt='Availability' className='img-fluid pt-5'/>
            <div className='box'>
              <div className='container '>
                  <h3 className='d-block text-sm-start fw-bold'>AVAILABILITY:</h3>
                  <div className='d-flex'>
                    <p className='text-sm-start  py-2'>Data always can be accessed when needed.</p>
                    <div className='num fw-bold '>03</div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
      </div>
      
      <div className='container'>
        <img src={map} alt='world-map' className='img-fluid'/>
      </div>
  
   <ScrollButton/>
   <Footer/>
   
      </>
      
  )
}

export default Home