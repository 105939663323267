import React from 'react'
import banner from '../Images/Warehousebanner.png';
import Reader from '../Images/Products/FX9600.png';
import Inlay1 from '../Images/Products/RS_MT_R70.png';
import Printer from '../Images/Products/ZT600R.png';
import Antenna from '../Images/Products/AN440.png';
import Indus from '../Images/Solution/Solution_Bin.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Industries = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR INDUSTRIES </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Industries</li>
            </ul>
          </nav>
          </div>
     
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR INDUSTRIES AND MANUFACTURING</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Industries:</h3>
                <p className='mt-3'>Manufacturing contain many different sectors such as mobile manufacturing, garments manufacturing, pharmaceutical manufacturing, consumer product manufacturing, vehicle manufacturing, construction machinery manufacturing & automative industry. </p>
                <p className='mt-3'>This industry includes many more sectors, and they all operate under the similar goals of keeping costs effective, increasing asset utilization, delivering products rapidly & reducing human erros. RSPM RFID solutions are the most effective option for industrial firms to overcome these barriers & ultimately helping them stay competitive. </p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Industries:</h3>
                <p className='mt-3' >RSPM hardware solution for industrial management, integrates RFID equipment and labels into conventional industrial operations. The new system enables companies to improve workflow efficiency by automatically collecting data in the warehouse work processes such as inbound, outbound, inventory, etc.</p>
                <img src ={Indus} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Industries:</h3>
                <ul className='mt-3'>
                  <li>Improves product tracking through WMS integration.</li>
                  <li>Improves product handling efficiency.</li>
                  <li>Increases inventory counting speed.</li>
                  <li>Helps fix incorrect inventory counts.</li>
                  <li>Helps improve product availability.</li>
                  <li>Helps automate product receipt, storage and distribution.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Printer} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Reader} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
