import React from 'react'
import banner from '../Images/Librarybanner.jpg';
import Reader from '../Images/Rfid-reader.jpg';
import Inlay1 from '../Images/Inlay1.jpg';
import handled from '../Images/handled-reader1.jpg';
import Libsol from '../Images/Solution/Solution_Library.jpg';
import Antenna from '../Images/Antenna.png';

import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Library = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Library</li>
            </ul>
          </nav>
          </div>
   
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR LIBRARY MANAGEMENT SYSTEM</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications for LMS:</h3>
                <p className='mt-3'>The purpose of an RSPM RFID application based library system is to automate and streamline various library operations such as book checkout, book return, inventory management, and security. By using RFID technology, the system can efficiently manage library operations, making it easier for library staff to manage books, and providing a better experience for library patrons.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in LMS:</h3>
                <p className='mt-3'>The RSPM solution is more cost-effective than the barcode system because it eliminates the need for human labour for tasks such as check-in, check-out, inventory holds, and so on. Using on-chip data storage and reprogrammable memory, our solution records the location of books and also allows for auto-detection of unauthorised items leaving the library, thereby eliminating the possibility of theft. Furthermore, the system is easily integrated with automated material handling units and supports locating specific items using RFID-based handheld readers..</p>
                
                <img src ={Libsol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in LMS:</h3>
                <ul className='mt-3'>
                  <li>Efficient book checkout.</li>
                  <li>Automated book return.</li>
                  <li>Real-time inventory management.</li>
                  <li>Improved security.</li>
                  <li>Enhanced search and locate.</li>
                  <li>Integration with library management software.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-5 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Reader} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={handled} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
              
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
