import React from 'react'
import banner from '../Images/Banner/Barcode_Printer.jpg';
import {ImHome} from 'react-icons/im';
import Printer from '../Images/Products/ZD200.png';
import Printer1 from '../Images/Products/ZD400.png';
import Printer2 from '../Images/Products/ZD611.png';
import Printer3 from '../Images/Products/ZD621.png';
import Printer4 from '../Images/Products/ZQ600.png';
import Printer5 from '../Images/Products/ZC100.png';
import Printer6 from '../Images/Products/ZC300.png';
import Printer7 from '../Images/Products/ZT400.png';
import Printer8 from '../Images/Products/ZT600.png';
import Footer from '../Footer';
import ZD200 from '../Documents/ZD200-Specification.pdf';
import ZD421 from '../Documents/ZD421-Specification.pdf';
import ZD611 from '../Documents/ZD611-Specification.pdf';
import ZD621 from '../Documents/ZD621-Specification.pdf';
import ZQ500 from '../Documents/ZQ500-Specification.pdf';
import ZC100 from '../Documents/ZC100-Specification.pdf';
import ZC300 from '../Documents/ZC300-Specification.pdf';
import ZT400 from '../Documents/ZT400-Specification.pdf';
import ZT600 from '../Documents/ZT600-Specification.pdf';
import ScrollButton from '../Home/Scrollbutton';
const Barcodeprint = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className='container'>
      <div className=" mb-0 bg-light">
          <img src ={banner} alt="RFID-slide" className='img-fluid'/> 
          <div className='d-flex p-1'>
            <ImHome className='m-1'/>
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
                <li className="breadcrumb-item ms-1"> <a href="/product">Products</a></li>
                <li className="breadcrumb-item active" aria-current="page">Barcode Printer</li>
              </ul>
            </nav>
          </div>
    
      </div>
    </div>
    <div className='container'>
      <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF BARCODE PRINTERS</h1>
        <h5 className='mt-4'>Searching for The Right Barcode Printer</h5>
      </div>
    </div>  
      <div className='container'>
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-3">
            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZD200 Series</h5>
                  <img src={Printer} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZD200} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZD421</h5>
                  <img src={Printer1} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZD421} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZD611</h5>
                  <img src={Printer2} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZD611} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZD621</h5>
                  <img src={Printer3} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZD621} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZQ500</h5>
                  <img src={Printer4} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZQ500} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZC100</h5>
                  <img src={Printer5} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZC100} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZC300</h5>
                  <img src={Printer6} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZC300} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZT400 Series</h5>
                  <img src={Printer7} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZT400} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZT600 Series</h5>
                  <img src={Printer8} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={ZT600} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


        </div> 
      </div>  
        <br/>
        <br/>
        <br/>
        <div className='container'>
      <div className='why-bg img-fluid'>
        <h1 className=' container d-inline-block text-center text-white fw-bold pt-5'>WHY CHOOSE RSPM BARCODE PRINTERS</h1>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5">
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>01</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Globally applying</h3>
                 <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card mt-5'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>02</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                 <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>03</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                 <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    <br/>  
        
      <ScrollButton/>
      <Footer/>
    </>
  )
}

export default Barcodeprint