import React from 'react'
import banner from '../Images/Assettracking.png';
import Reader from '../Images/Rfid-reader.jpg';
import Inlay1 from '../Images/Inlay1.jpg';
import handled from '../Images/handled-reader1.jpg';
import Antenna from '../Images/Antenna.png';
import Asset from '../Images/Solution/Solution_Asset.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Assettracking  = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Asset Tracking </li>
            </ul>
          </nav>
          </div>
     
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR ASSET TRACKING</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Asset Tracking:</h3>
                <p className='mt-3'>RSPM RFID asset tracking is a complete asset life cycle management solution that tracks and monitors assets in real time. RFID tags attached to your equipment allow you to monitor and maintain it until the end of the tool's life.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Asset Tracking:</h3>
                <p className='mt-3'>In its simplest form, RSPM RFID asset tracking is a way of automating the management and locating process of physical assets. It works by loading an RFID tag with data and attaching it to a relevant asset. This data can include anything from name, condition, amount, and location.</p>
                
                <img src ={Asset} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Asset Tracking:</h3>
                <ul className='mt-3'>
                  <li>Tracking multiple assets simultaneously.</li>
                  <li>Effectively simplify the process flow.</li>
                  <li>Maximising accuracy of inventory.</li>
                  <li>Tracking down misplaced or lost assets.</li>
                  <li>Collecting/ Monitoring data in real-time.</li>
                  <li>Reduce human intervention and errors.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={handled} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Reader} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
