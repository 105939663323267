import React from 'react'
import Reader from '../Images/Fixed-Reader.png';
import Reader1 from '../Images/handled-reader2.png';
import Inlay from '../Images/Inlay.png';
import Inlay2 from '../Images/Inlay2.png';
import Antenna from '../Images/Antenna.png';
import Antenna1 from '../Images/Antenna-AN720.png';

import RFIDPrinter from '../Images/Rfid-Printer.png'
import Consumables from '../Images/Consumables.png';
import Consumables1 from '../Images/Consumables1.png';
import K40Pro from '../Images/K40Pro.png';
import uface from '../Images/Uface402.png';
import Boombarrier from '../Images/Boombarrier.png';
import ZD621D from '../Images/ZD621D.png';
import ZT610 from '../Images/ZT610.png';
import Scanner from '../Images/Scanner.png';
import Scanner1 from '../Images/Scanner1.png';
import Trypod from '../Images/Trypod.png';
import MobileCom from '../Images/MobileCom.png';
import MobileCom1 from '../Images/MobileCom1.png';

import {HiArrowCircleRight} from 'react-icons/hi';

import {MdOutlineDoubleArrow} from 'react-icons/md';


import product_banner from  '../Images/Products.jpg' 
import {ImHome} from 'react-icons/im';

import '../card.css';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
const Products = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
  <>
    <div className='container'>
        <img src ={product_banner} alt="RFID-slide" className='img-fluid'/> 
         <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Products</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='container'>
        <div className='Prod-head-title text-center fw-bold my-5'><h1>TYPES OF RSPM PRODUCTS</h1>
         
        </div>
      </div>
      
    <div className='container '>
    <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 ">
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">RFID Reader</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Reader} alt='Rfid-Reader' className=''/>
                                <img src={Reader1} alt='Rfid-Reader' className=''/>
                            </div>
                                <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Readers</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/Rfidreader'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                 </div>
                        </div>
                    </div>   
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                            <h5 className="home-card-title">RFID Antenna</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Antenna} alt='Rfid-Antenna' className=''/>
                                <img src={Antenna1} alt='Rfid-Antenna1' className=''/>
                            </div>
                            
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Antenna</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/Rfidantenna'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                 </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">RFID Tags & Inlays</h5>
                            <div className='row row-cols-2 mt-5 p-2'>
                                <img src={Inlay} alt='Rfid-Tag&Inlays' className=''/>
                                <img src={Inlay2} alt='Rfid-Tag&Inlays1' className=''/>
                            </div>
                            
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Tags & Inlays</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/Rfidtags'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                 </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                        <div className='row'></div>
                            <h5 className="home-card-title">RFID Printer</h5>
                            <img src={RFIDPrinter} alt='RFID Printer' className='card-image'/>
                            
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Printer</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/Rfidprinter'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                 </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">Barcode Scanner</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Scanner} alt='Biometric' className=''/>
                                <img src={Scanner1} alt='Biometric' className=''/>
                            </div>
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Barcode Scanner</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/barcodescanner'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                 </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                            <h5 className="home-card-title">Barcode Printer</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={ZD621D} alt='Biometric' className=''/>
                                <img src={ZT610} alt='Biometric' className=''/>
                            </div>
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Barcode Printer</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/barcodeprinter'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    


                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                        <div className='row'></div>
                            <h5 className="home-card-title">Mobile Computers</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={MobileCom} alt='MobileCom' className=''/>
                                <img src={MobileCom1} alt='MobileCom1' className=''/>
                            </div>
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Mobile Computers</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/mobilecomputer'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                            <h5 className="home-card-title">Access Control</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Boombarrier} alt='Boombarrier' className='access-cont-image ms-4'/>
                                <img src={Trypod} alt='Trypod' className='access-cont-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={K40Pro} alt='Boombarrier' className='access-cont-image ms-3'/>
                                <img src={uface} alt='Trypod' className='access-cont-image'/>
                            </div>
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Access Control</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/accesscontrol'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">Consumables</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Consumables} alt='Consumables' className=''/>
                                <img src={Consumables1} alt='Consumables' className=''/>
                            </div>
                            <div className="sol-overlay">
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Consumables</h5>
                                            <div className='view-more-overlay d-flex ms-3'><h5><a href='/product/consumables'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>   
                </div>
                
            </div> 
        <br/>
    </div>
    
      <ScrollButton/>
      <Footer/>
  </>
    
  )
}

export default Products