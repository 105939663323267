import React from 'react'
import banner from '../Images/Garments-banner.jpg';
import Reader from '../Images/Rfid-reader.jpg';
import Inlay1 from '../Images/Jewelry-tag.png';
import handled from '../Images/handled-reader1.jpg';
import Antenna from '../Images/Antenna.png';
import Garsol from '../Images/Solution/Solution_Garments.jpg';

import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Garment = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Garments</li>
            </ul>
          </nav>
          </div>
 
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR GARMENTS</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Garments:</h3>
                <p className='mt-3'>RSPM RFID Tracking Garment Assets using RFID technology provides retailers with a valuable tool for optimizing inventory levels, preventing theft, and improving supply chain efficiency. The technology enables retailers to have visibility into the movement of their inventory throughout the supply chain, which can help them make more informed decisions about their operations.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Garments:</h3>
                <p className='mt-3'>RFID technology provides several solutions for the jewellery industry, including inventory management, theft prevention, product authentication, supply chain visibility, and enhanced customer experience.</p>
                
                <img src ={Garsol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Garments:</h3>
                <ul className='mt-3'>
                  <li>Improved Inventory Management.</li>
                  <li>Improved Customer Experience.</li>
                  <li>Product Authentication.</li>
                  <li>Increased Efficiency.</li>
                  <li>Enhanced Security.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={handled} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Reader} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
export default Garment