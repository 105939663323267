import React from 'react'
import banner from '../Images/Banner/RFID_Tags.jpg';
import {ImHome} from 'react-icons/im';
import LF from '../Images/Products/RFID_LF_Card_125Khz.png';
import HF1K from '../Images/Products/RFID_HF_Card_1K.png';
import HF4K from '../Images/Products/RFID_HF_Card_4K.png';
import UHF from '../Images/Products/RFID_UHF_Card.png';
import HFLF from '../Images/Products/RFID_HF_LF_Card.png';
import UHFLF from '../Images/Products/RFID_UHF_LF_Card.png';
import UHFHF from '../Images/Products/RFID_UHF_HF_Card.png';
import UHFHFLF from '../Images/Products/RFID UHF_HF_LF_Card.png'; 
import DBT from '../Images/Products/Dogbone_Smatrac.png';
import M7RP6 from '../Images/Products/M7_RP6.png';
import Miniweb from '../Images/Products/Miniweb.png';
import T9662 from '../Images/Products/9662.png';
import MR6PJW from '../Images/Products/Monza_R6-P-JW.png';
import BeltRFID from '../Images/Products/Smatrac_Belt_RFID_Paper.png';
import RSMTR50 from '../Images/Products/RS_MT_R50.png';
import RSMTR60 from '../Images/Products/RS_MT_R60.png';
import RSMTR70 from '../Images/Products/RS_MT_R70.png';
import RSMTR80 from '../Images/Products/RS_MT_R80.png';
import RSHTR100 from '../Images/Products/RS_HT_100.png';
import TYT from '../Images/Products/RS_TYT_90.png';
import ALN9640 from '../Images/Products/ALN9640.png';
import WTL from '../Images/Products/Monzo-R6-P_WTL.png';

import Footer from '../Footer';


import ScrollButton from '../Home/Scrollbutton';
const Rfidtags = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="RFID-slide" className='img-fluid'/> 
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/product">Prodcuts</a></li>
              <li className="breadcrumb-item active" aria-current="page">RFID Tags and Cards</li>
            </ul>
          </nav>
        </div>
     
      </div>
      <div className='container'>
      <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF RFID TAGS AND CARDS</h1>
        <h5 className='mt-4'>Searching for The Right RFID Tags</h5>
      </div>
      </div>
      <div className='container'>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-3">
          <div className='col '>
                <div className='card'>
                  <div className='card-body card-prod'>
                  <h5 className="card-title">RFID LF CARD 125Khz</h5>
                    <img src={LF} alt='Rfid-Reader' className='img-fluid'/>
                    <div className="overlay">
                      
                    </div>
                  </div>
                </div>
              </div>
            
            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title">RFID HF CARD 1K</h5>
                  <img src={HF1K} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                  </div>
                </div>
              </div>
            </div>
            
            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title">RFID HF CARD 4K</h5>
                  <img src={HF4K} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">RFID UHF CARD</h5>
                  <img src={UHF} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                  
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">RFID HF+LF CARD</h5>
                  <img src={HFLF} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                  
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">RFID UHF+LF CARD</h5>
                  <img src={UHFLF} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                  
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">RFID UHF+HF CARD</h5>
                  <img src={UHFHF} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                  
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">RFID UHF+HF+LF CARD</h5>
                  <img src={UHFHFLF} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                  
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">Dogbone Smatrac</h5>
                  <img src={DBT} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">M7 RP-6</h5>
                  <img src={M7RP6} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">Miniweb Monzo R6-P</h5>
                  <img src={Miniweb} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">Tag 9662</h5>
                  <img src={T9662} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">Monzo R6-P Jewellery Tag</h5>
                  <img src={MR6PJW} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">Smartrac Belt RFID Paper</h5>
                  <img src={BeltRFID} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">ALN9640</h5>
                  <img src={ALN9640} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Metal Tag RSMT50</h5>
                  <img src={RSMTR50} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Metal Tag RSMTR60</h5>
                  <img src={RSMTR60} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Metal Tag RSMTR70</h5>
                  <img src={RSMTR70} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Metal Tag RSMTR80</h5>
                  <img src={RSMTR80} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Laundary Tag</h5>
                  <img src={WTL} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Tyre Tag</h5>
                  <img src={TYT} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div className='col'>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title ">UHF Hard Tag RSHTR100</h5>
                  <img src={RSHTR100} alt='Rfid-Antenna' className='img-fluid'/>
                  <div className="overlay">
                    <div className='text-center mt-2'>
                      
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div> 
        <br/>
      </div>
      <div className='container'>
        <div className='why-bg'>
          <h1 className='RFID-head text-center fw-bold pt-5'>WHY CHOOSE RSPM RFID READER</h1>
          <h6 className='RFID-subhead text-center'>Why Choose</h6>
          <div className="container row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5">
            <div className='col'>
              <div className='RFID-card'>
                <div className='p-4'>
                  <div className='RFID-num fw-bold '>01</div>
                  <div className='RFID-Content mt-5'>
                  <h3 className='mt-3 fw-bold'>Globally applying</h3>
                  <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='RFID-card mt-5'>
                <div className='p-4'>
                  <div className='RFID-num fw-bold '>02</div>
                  <div className='RFID-Content mt-5'>
                  <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                  <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='RFID-card'>
                <div className='p-4'>
                  <div className='RFID-num fw-bold '>03</div>
                  <div className='RFID-Content mt-5'>
                  <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                  <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>  
      <ScrollButton/>
      <Footer/>
    </>
  )
}

export default Rfidtags