import React from 'react'
import banner from '../Images/Smart-Attendance.jpg';
import handled from '../Images/Products/RFD40.png';
import Printer from '../Images/Products/ZT600R.png';
import Antenna from '../Images/Products/AN440.png';
import Inlay1 from '../Images/Products/9662.png';
import Animalsol from '../Images/Solution/Solution_Animal.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Animal = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Animal Tracking</li>
            </ul>
          </nav>
          </div>
     
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR ANIMAL TRACKING</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Animal Tracking:</h3>
                <p className='mt-3'>RSPM Animal Tracking System provides RFID technology that enables the identification and tracking of animals using electronic tags. The RFID system allows for accurate and timely tracking of animals, which can help farmers and animal owners manage their herds or flocks more effectively.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Animal Tracking:</h3>
                <p className='mt-3'>Our solution uses a unique numbered RFID tag that provides a very efficient method of identifying animals and collecting data far more efficiently. Our Animal Tracking Solution provides Farm Management for each individual animal and record keeping like Vaccination Details, Health and Breeding Details. Our Animal Tracking Solution also automates the process of feeding, weighing, disease control, subsidies, breeding practices as well as quality and traceability assurance.</p>
                <img src ={Animalsol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Animal Tracking:</h3>
                <ul className='mt-3'>
                <li>Reduce pilferage, shrinkage and loss through improved monitoring of RFID tagged Animals and other shipments.</li>
                    <li>Reduce shelter costs by improving space management.</li>
                    <li>Improve revenues by providing end consumer information.</li>
                    <li>Flexibility, Speed and Efficiency and Accuracy.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Printer} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={handled} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
