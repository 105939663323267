import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../Images/Slide1.png'
import slide2 from '../Images/Banner/RFID_Readers.jpg'
import slide3 from '../Images/Banner/RFID_Printer.jpg'
import slide4 from '../Images/Banner/RFID_Antenna.jpg'
import slide5 from '../Images/Banner/Mobile_Computer.jpg'
import slide6 from '../Images/Banner/RFID_Tags.jpg'
import slide7 from '../Images/Banner/Access_Control.jpg'
import slide8 from '../Images/Banner/Barcode_Scanner.jpg'
import slide9 from '../Images/Banner/Consumables.jpg'
import slide10 from '../Images/Slide2.jpg'
import handled from '../Images/handled-reader.jpg'
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import {Col, Row} from 'react-bootstrap'
import '../App.css'
function Slider() {
    let navigate = useNavigate(); 
    const about = () =>{ 
    let path = `about`; 
    navigate(path);
  }
  const contact = () =>{ 
    let path = `contactus`; 
    navigate(path);
  }
    return (
    <div className="container">
      <Carousel className='' variant='light'>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-cover" src={slide1} alt="First slide"/>
          <Carousel.Caption className='row d-block'>
          <div className='slider-caption'>
            <div>
              <h1 className='slider-head text-uppercase fw-bold'>One-stop-shop Rfid Service Provider</h1>
              <br></br>
            </div>
            <dvi>
              <p>The complete RFID product line including tags, antennas, fixed and handheld readers, and system integration software.</p>
              <div className=''>
                <Button className='btn-abtus rounded-pill btn btn-primary ms-4' onClick= {about}> MORE ABOUT RSPM</Button>
                <Button className='btn-quote rounded-pill btn btn-secondary ms-4' onClick={contact}> GET QUOTE</Button>
              </div>
            </dvi>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-25 img-fluid" src={slide2} alt="Second slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide3} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide4} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide5} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide6} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide7} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide8} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide9} alt="Third slide"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100 h-50 img-fluid" src={slide10} alt="Third slide"/>
        </Carousel.Item>
      </Carousel>
    </div>
    );
  }
  
  export default Slider;