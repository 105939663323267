import React from 'react'
import banner from '../Images/Vehicle_Banner.jpg';
import Reader from '../Images/Rfid-reader.jpg';
import Inlay1 from '../Images/Inlay1.jpg';
import Printer from '../Images/Rfid-Printer.jpg';
import Antenna from '../Images/Antenna.png';
import Vehiclesol from '../Images/Solution/Solution_Vehicle.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Vehicle = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Vehicle</li>
            </ul>
          </nav>
          </div>

      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR VEHICLE TRACKING</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Vehicle:</h3>
                <p className='mt-3'>RSPM RFID solution for vehicle management enhances levels of security and intelligence through the functions of automatic vehicle identification, tracking, fleet management, etc.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Vehicle:</h3>
                <p className='mt-3' >RSPM hardware solution for vehicle management combines RFID tags, readers, and antennae to match and identify vehicle information. The equipment helps to identify the identity of the vehicle and recognize the vehicle's status.</p>
                
                <img src ={Vehiclesol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Vehicle:</h3>
                <ul className='mt-3'>
                  <li>Improve vehicle identifying speed and passing efficiency.</li>
                  <li>Realize precise information collection of vehicle traffic in categories.</li>
                  <li>Provide an informationized method of vehicle supervision for enterprises.</li>
                  <li>Save manpower, avoid human mistakes and interventions.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Printer} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Reader} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
