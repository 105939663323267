import React from 'react'
import banner from '../Images/Banner/Barcode_Scanner.jpg';
import {ImHome} from 'react-icons/im';
import Scaner from '../Images/Products/LI4278.png';
import Scaner1 from '../Images/Products/LS2208.png';
import Scaner2 from '../Images/Products/LI2208.png';
import Scaner3 from '../Images/Products/DS8100.png';
import Scaner4 from '../Images/Products/DS9300.png';
import Scaner5 from '../Images/Products/DS3600.png';
import Scaner6 from '../Images/Products/CS60.png';
import Scaner7 from '../Images/Products/DS9900.png';
import Scaner8 from '../Images/Products/DS7708.png';
import Footer from '../Footer';
import LI4278 from '../Documents/LI4278-Specification.pdf';
import LS2208 from '../Documents/LS2208-Specification.pdf';
import LI2208 from '../Documents/LI2208-Specification.pdf';
import DS9300 from '../Documents/DS9300-Specification.pdf';
import CS60 from '../Documents/CS60-Specification.pdf';
import DS9900 from '../Documents/DS9900-Specification.pdf';
import DS7708 from '../Documents/DS7708-Specification.pdf';
import DS3600 from '../Documents/DS3600-Specification.pdf';
import DS8100 from '../Documents/DS8100-Specification.pdf';
import ScrollButton from '../Home/Scrollbutton';
const Barcodescanner = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className='container'>
      <div className="mb-0 bg-light">
          <img src ={banner} alt="RFID-slide" className='img-fluid'/> 
          <div className='d-flex p-1'>
            <ImHome className='m-1'/>
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
                <li className="breadcrumb-item ms-1"> <a href="/product">Products</a></li>
                <li className="breadcrumb-item active" aria-current="page">Barcode Scanner</li>
              </ul>
            </nav>
          </div>
        
      </div>
    </div>
    <div className='container'>
      <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF BARCODE SCANNERS</h1>
        <h5 className='mt-4'>Searching for The Right Barcode Scanner</h5>
      </div>
    </div>
      <div className='container'>
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-3">
            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">LI4278</h5>
                  <img src={Scaner} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={LI4278} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">LS2208-1D Scanner</h5>
                  <img src={Scaner1} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={LS2208} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">LI2208</h5>
                  <img src={Scaner2} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={LI2208} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">DS8100</h5>
                  <img src={Scaner3} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={DS8100} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">DS9300</h5>
                  <img src={Scaner4} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={DS9300} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">DS3600 Series</h5>
                  <img src={Scaner5} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={DS3600} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">CS60</h5>
                  <img src={Scaner6} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={CS60} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">DS9900 Series</h5>
                  <img src={Scaner7} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={DS9900} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col '>
              <div className='card'>
                <div className='card-body card-prod'>
                <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">DS7708</h5>
                  <img src={Scaner8} alt='Rfid-Reader' className='img-fluid'/>
                  <div className="overlay">
                    
                    <div className='download-cat'>
                      <a href={DS7708} target='blank' >Download Spec</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


        </div> 
      </div>  
        <br/>
        <br/>
        <br/>
      <div className='container'>
      <div className='why-bg img-fluid'>
        <h1 className=' container d-inline-block text-center text-white fw-bold pt-5'>WHY CHOOSE RSPM BARCODE SCANNERS</h1>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5">
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>01</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Globally applying</h3>
                 <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card mt-5'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>02</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                 <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>03</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                 <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    <br/>  
      <ScrollButton/>
      <Footer/>
    </>
  )
}

export default Barcodescanner