import React from 'react'
import Reader from '../Images/Fixed-Reader.png';
import Reader1 from '../Images/handled-reader2.png';
import Reader2 from '../Images/Products/FX7500.png';
import Reader3 from '../Images/Products/DesktopReader.png';
import Inlay from '../Images/Products/ALN9640.png'
import Inlay1 from '../Images/Inlay2.png';
import Inlay2 from '../Images/Products/9662.png';
import Inlay3 from '../Images/Products/M7_RP6.png';
import Antenna from '../Images/Antenna.png';
import Antenna1 from '../Images/Antenna-AN720.png';
import Antenna2 from '../Images/Products/RS900_1.png';
import Antenna3 from '../Images/Products/RS1200_1.png';
import Retail from '../Images/Retail.jpg';
import Library from '../Images/Library.jpg'
import Warehouse from '../Images/Warehouse.png';
import RFIDPrinter from '../Images/Rfid-Printer.png'
import Consumables from '../Images/Consumables.png';
import Consumables1 from '../Images/Consumables1.png';
import Consumables2 from '../Images/Products/RFID_HF_Card_4K.png';
import Consumables3 from '../Images/Products/Barcode_Label_Polyster.png';
import K40Pro from '../Images/K40Pro.png';
import uface from '../Images/Uface402.png';
import Boombarrier from '../Images/Boombarrier.png';
import ZD621D from '../Images/ZD621D.png';
import ZT610 from '../Images/ZT610.png';
import ZD400 from '../Images/Products/ZD400.png';
import ZD200 from '../Images/Products/ZD200.png';
import Scanner from '../Images/Scanner.png';
import Scanner1 from '../Images/Scanner1.png';
import Scanner2 from '../Images/Products/LI4278.png';
import Scanner3 from '../Images/Scanner.png';
import Trypod from '../Images/Trypod.png';
import MobileCom from '../Images/MobileCom.png';
import MobileCom1 from '../Images/MobileCom1.png';
import MobileCom2 from '../Images/MobileCom1.png';
import MobileCom3 from '../Images/MobileCom.png';
import { Button } from 'react-bootstrap';
import {HiArrowCircleRight} from 'react-icons/hi';
import{GiTriangleTarget} from 'react-icons/gi';
import {MdOutlineDoubleArrow} from 'react-icons/md';
import logo1 from '../Images/Logo1.png';
import { useNavigate } from "react-router-dom";

const Catalogues = () => {
    let navigate = useNavigate(); 
    const solutions = () =>{ 
    let path = `solutions`; 
    navigate(path);
  }
    const products = () =>{ 
    let path = `product`; 
    navigate(path);
  }
  const retail = () =>{ 
    let path = `solutions/retail`; 
    navigate(path);
  }
  const warehouse = () =>{ 
    let path = `solutions/warehouse`; 
    navigate(path);
  }
  const library = () =>{ 
    let path = `solutions/library`; 
    navigate(path);
  }


  
  return (
    <>
        <div className='container'>
            <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 ">
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">RFID Reader</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Reader} alt='Rfid-Reader' className='home-prod-card-image'/>
                                <img src={Reader1} alt='Rfid-Reader' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Reader2} alt='Rfid-Reader' className='home-prod-card-image mt-4'/>
                                <img src={Reader3} alt='Rfid-Reader' className='home-prod-card-image mt-2'/>
                            </div>
                                <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Readers</h5>
                                            <div className='view-more-overlay d-flex '><h5><a href='/product/Rfidreader'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>FX7500 Reader</a></li>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>FX9600 Reader</a></li>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>RFD8500 Reader</a></li>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>ART7000 RTLS Reader</a></li>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>RFD40UHF Reader</a></li>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>MC3300 RFID Series</a></li>
                                                <li className='card-text'> <a href='/product/Rfidreader' ><MdOutlineDoubleArrow className='me-1'/>RFID Smart Connect Desktop Reader</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>   
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                            <h5 className="home-card-title">RFID Antenna</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Antenna} alt='Rfid-Antenna' className='home-prod-card-image'/>
                                <img src={Antenna1} alt='Rfid-Antenna1' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Antenna2} alt='Rfid-Antenna' className='home-prod-card-image '/>
                                <img src={Antenna3} alt='Rfid-Antenna1' className='home-prod-card-image'/>
                            </div>
                            
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Antenna</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/Rfidantenna'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/Rfidantenna' ><MdOutlineDoubleArrow className='me-1'/>AN480 Antenna</a></li>
                                                <li className='card-text'> <a href='/product/Rfidantenna' ><MdOutlineDoubleArrow className='me-1'/>AN440 Antenna</a></li>
                                                <li className='card-text'> <a href='/product/Rfidantenna' ><MdOutlineDoubleArrow className='me-1'/>Ultra Rugged RS900 UHF Antenna</a></li>
                                                <li className='card-text'> <a href='/product/Rfidantenna' ><MdOutlineDoubleArrow className='me-1'/>Ultra Rugged RS1200 UHF Antenna</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">RFID Tags & Inlays</h5>
                            <div className='row row-cols-2 mt-5 p-2'>
                                <img src={Inlay} alt='Rfid-Tag&Inlays' className='home-prod-card-image mt-4'/>
                                <img src={Inlay1} alt='Rfid-Tag&Inlays1' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 mt-5 p-2'>
                                <img src={Inlay2} alt='Rfid-Tag&Inlays' className='home-prod-card-image'/>
                                <img src={Inlay3} alt='Rfid-Tag&Inlays1' className='home-prod-card-image'/>
                            </div>
                            
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Tags & Inlays</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/Rfidtags'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                        <div className='row'></div>
                            <h5 className="home-card-title">RFID Printer</h5>
                            <img src={RFIDPrinter} alt='RFID Printer' className='card-image'/>
                            
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2 '>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">RFID Printer</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/Rfidprinter'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/Rfidprinter' ><MdOutlineDoubleArrow className='me-1'/>ZD621R</a></li>
                                                <li className='card-text'> <a href='/product/Rfidprinter' ><MdOutlineDoubleArrow className='me-1'/>ZD6211R</a></li>
                                                <li className='card-text'> <a href='/product/Rfidprinter' ><MdOutlineDoubleArrow className='me-1'/>ZT600 Series RFID Industrial Printers</a></li>
                                                <li className='card-text'> <a href='/product/Rfidprinter' ><MdOutlineDoubleArrow className='me-1'/>ZT400 Series</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">Barcode Scanner</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Scanner} alt='Biometric' className='home-prod-card-image'/>
                                <img src={Scanner1} alt='Biometric' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Scanner2} alt='Biometric' className='home-prod-card-image'/>
                                <img src={Scanner3} alt='Biometric' className='home-prod-card-image'/>
                            </div>
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Barcode Scanner</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/barcodescanner'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>LS2208-1D Scanner</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>LI2208</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>DS8100</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>DS9300</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>CS60</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>DS9900</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>DS7708</a></li>
                                                <li className='card-text'> <a href='/product/barcodescanner' ><MdOutlineDoubleArrow className='me-1'/>DS3600 Series</a></li>
                                                
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                            <h5 className="home-card-title">Barcode Printer</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={ZD621D} alt='Biometric' className='home-prod-card-image'/>
                                <img src={ZT610} alt='Biometric' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={ZD400} alt='Biometric' className='home-prod-card-image'/>
                                <img src={ZD200} alt='Biometric' className='home-prod-card-image'/>
                            </div>
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Barcode Printer</h5>
                                            <div className='view-more-overlay d-flex '><h5><a href='/product/barcodeprinter'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>LI4278</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZD200 Series</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZD421</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZD611</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZD621</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZQ500</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZC100</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZC300</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZT400 Series</a></li>
                                                <li className='card-text'> <a href='/product/barcodeprinter' ><MdOutlineDoubleArrow className='me-1'/>ZT600 Series</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>
                    


                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                        <div className='row'></div>
                            <h5 className="home-card-title">Mobile Computers</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={MobileCom} alt='MobileCom' className='home-prod-card-image'/>
                                <img src={MobileCom1} alt='MobileCom1' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={MobileCom2} alt='MobileCom' className='home-prod-card-image'/>
                                <img src={MobileCom3} alt='MobileCom1' className='home-prod-card-image'/>
                            </div>
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2 '>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Mobile Computers</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/mobilecomputer'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/mobilecomputer' ><MdOutlineDoubleArrow className='me-1'/>MC2200/MC2700</a></li>
                                                <li className='card-text'> <a href='/product/mobilecomputer' ><MdOutlineDoubleArrow className='me-1'/>MC3300AX</a></li>
                                                <li className='card-text'> <a href='/product/mobilecomputer' ><MdOutlineDoubleArrow className='me-1'/>MC9300</a></li>
                                                <li className='card-text'> <a href='/product/mobilecomputer' ><MdOutlineDoubleArrow className='me-1'/>TC21/TC26</a></li>
                                                <li className='card-text'> <a href='/product/mobilecomputer' ><MdOutlineDoubleArrow className='me-1'/>TC53/TC58</a></li>
                                                <li className='card-text'> <a href='/product/mobilecomputer' ><MdOutlineDoubleArrow className='me-1'/>TC72/TC77</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod 1 card-body">
                            <h5 className="home-card-title">Access Control</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Boombarrier} alt='Boombarrier' className='home-prod-card-image ms-4'/>
                                <img src={Trypod} alt='Trypod' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={K40Pro} alt='Boombarrier' className='home-prod-card-image ms-3'/>
                                <img src={uface} alt='Trypod' className='home-prod-card-image'/>
                            </div>
                            <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Access Control</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/accesscontrol'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Boom Barrier</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>RFID Controller</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Trypad Trunstiles</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Flap Barrier</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Backage Scanner</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Walk Through Metal Detector</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Handheld Metal Detector</a></li>
                                                <li className='card-text'> <a href='/product/accesscontrol' ><MdOutlineDoubleArrow className='me-1'/>Biometric Readers</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-prod  card-body">
                            <h5 className="home-card-title">Consumables</h5>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Consumables} alt='Consumables' className='home-prod-card-image'/>
                                <img src={Consumables1} alt='Consumables' className='home-prod-card-image'/>
                            </div>
                            <div className='row row-cols-2 d-flex'>
                                <img src={Consumables2} alt='Consumables' className='home-prod-card-image'/>
                                <img src={Consumables3} alt='Consumables' className='home-prod-card-image'/>
                            </div>
                                <div className="home-overlay">
                                    <div className='cotainer row row-cols-2 m-2'>
                                        <div className='col'>
                                            <h5 className="card-title-overlay">Consumable</h5>
                                            <div className='view-more-overlay d-flex'><h5><a href='/product/consumables'>View More</a></h5>
                                                <div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <ul className='m-1 p-1'>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>Barcode Label Chromo</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>Barcode Label Polyster</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>BarCode Label Synthatic</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>BarCode Label Silver and WhitePolyster</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>Wax Ribbon</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>Resin Ribbon</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>Premium Wax Ribbon</a></li>
                                                <li className='card-text'> <a href='/product/consumables' ><MdOutlineDoubleArrow className='me-1'/>Wax Resin Ribbon</a></li>
                                            </ul>
                                            
                                        </div>   
                                    </div>
                                </div>
                        </div>
                    </div>   
                </div>
                <div className= 'container-fluid p-5'>
                    <Button className='Cat-btn shadow-lg d-flex btn btn-Primary rounded-pill p-2 m-1 ' onClick={products} type="submit" style={{ width: 'Auto-content' }}>
                    <div className='ms-2'>TO FIND MORE RSPM RFID</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
                    </Button>
                </div>
            </div>
            <div className='container'>
                <div className=" row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 mt-3 ">
                    <div className='col'>
                        <div className='row '>
                            <div className='container'>
                                <img src ={logo1} alt="Logo1"  className="img-fluid prod-image m-2 p-2"/>
                                <h3 className='home-hot-sale fw-bold'>Hot- Sale RSPM RFID</h3>
                                <h5 className='home-pop-prod'>Popular Prodcuts</h5> 
                            </div> 
                        </div>
                        
                    </div>
                    
                    <div className="col">
                        <div className="hot-card card">
                            <div className="card-body">
                                <h5 className="card-prod-title text-bold d-block mx-3">HOT</h5>
                                <img src={Reader} alt='Rfid-Reader' className='card-image'/>
                                <div className="home-overlay">
                                <p className="card-prod-text p-1">Bring a new level of cost efficiency to asset tracking and inventory management. Achieve maximum visibility and efficiency in high-volume, rugged environments — from industry leading read rates and exceptional read accuracy to superior RF sensitivity.</p>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="hot-card card">
                            <div className="card-body">
                                <h5 className="card-prod-title">HOT</h5>
                                <img src={Reader1} alt='Rfid-Reader' className='card-image'/>
                                <div className="home-overlay">
                                <p className="card-prod-text">Turn your computer into an RFID reader. Easily add high-performance next-generation RFID reading and 1D/2D barcode scanning to the mobile devices of today and tomorrow.</p>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="hot-card card">
                            <div className=" card-body">
                                <h5 className="card-prod-title">HOT</h5>
                                <img src={Inlay2} alt='Rfid-Reader' className='card-image'/>
                                <div className="home-overlay">
                                <p className="card-prod-text">Inlays that offer improved read ranges with off-axis placement, on diverse materials, densely packed items, with overhead readers, as well as when extended read ranges are required.</p>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>  
                <br/>
            </div>
            
            <div className='container-fluid bg-light '>
                <div className='container justify-content-md-center '>
                <h1 className='home-head  fw-bold p-5'>RSPM RFID Application</h1>
                </div>
                <div className='container'>
                    <p className=''>Strengthened by over 8 years of experience in the field of RFID and had worked on India's biggest RFID implementations, RSPM RFID provider empowered solutions help end-users to reach and sustain consistently high levels of RFID identification performance.</p>
                </div>
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-0 p-4">
                    <div className="col">
                        <div className="application-card card">
                        <img src={Retail} alt='Retail' className='card-img-top'/>
                            <div className="card-app-body container">
                                <div className='card-app-icon'><h4><GiTriangleTarget/></h4></div>
                                <h4 className="card-app-title">Retail</h4>
                                <p className="card-app-text text-center">Bringing item level visibility to increase sales, reduce loss and enhance consumers' experiences in the store.</p>
                                <div className= 'container-fluid p-2' >
                                    <Button className='app-btn shadow-lg d-flex btn btn-Primary rounded-pill p-2 m-1' onClick={retail} type="submit" style={{ width: 'Auto-content' }}>
                                    <div className='ms-2'>Explore</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="application-card card">
                            <div className="card-app-body container">
                            <div className='row' ></div>
                                <div className='card-app-icon1 '><h4><GiTriangleTarget/></h4></div>
                                <h4 className="card-app-title">Warehouse</h4>
                                <p className="card-app-text text-center">RSPM digital warehouse management system adopts advanced RFID technology to increase warehouse sales.</p>
                                <div className= 'text-center p-2'>
                                    <Button className='app-btn shadow-lg d-flex btn btn-Primary rounded-pill p-2 m-1' onClick={warehouse} type="submit" style={{ width: 'Auto-content' }}>
                                    <div className='ms-2'>Explore</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                    </Button>
                                </div>
                            </div>
                            <img src={Warehouse} alt='Warehouse' className='card-img-top'/>
                        </div>
                        
                    </div>
                    <div className="col">
                        <div className="application-card card">
                        <img src={Library} alt='Library' className='card-img-top'/>
                            <div className="card-app-body container-fluid">
                            <div className='card-app-icon'><h4><GiTriangleTarget/></h4></div>
                                <h4 className="card-app-title">Library</h4>
                                <p className="card-app-text text-center"> The use of RSPM's RFID solutions in library management achieve fast check-out/ check-in and inventorying.</p>
                                <div className= 'container-fluid p-2'>
                                    <Button className='app-btn shadow-lg d-flex rounded-pill p-2 m-1' onClick={library} type="submit" style={{ width: 'Auto-content' }}>
                                    <div className='ms-2'>Explore</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
                                    </Button>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className= 'container-fluid p-5'>
                        <Button className='app-more-btn d-flex shadow-lg rounded-pill p-2 m-1 ' onClick={solutions} type="submit" style={{ width: 'Auto-content' }}>
                        <div className='ms-2'>More RFID Application</div><div className='icon flex ms-2'><HiArrowCircleRight/></div>
                        </Button>
                    </div>
                </div>  
            </div> 
        </div>
    </>
    
    
  )
}

export default Catalogues
