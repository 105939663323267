import React from 'react'
import Retail from '../Images/Retail.jpg';
import Library from '../Images/Library.jpg'
import ware from '../Images/Warehouse.png';
import Asset from '../Images/Assettracking.png'
import Jewell from '../Images/Jewelry-banner.jpg'
import solutionbg from  '../Images/Solutions.jpg' 
import {ImHome} from 'react-icons/im';
import {BsArrowRight} from 'react-icons/bs';
import '../card.css';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
const Solutions = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
  <>
  <div className='container'>
    <div className="mt-0">
        <div className=''>
          <img src ={solutionbg} alt="Solution-slide" className='img-fluid'/> 
          
          <div className='d-flex p-1'>
            <ImHome className='m-1'/>
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Solutions</li>
              </ul>
            </nav>
          </div>
        </div>
    </div>
    </div>

    <div className='container bg-light'  >
      <div className="container-fluid position-relative row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-1 p-4">
      
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Garments</h3>
                  <div className='mt-4'>Allow the item level retail management. It will improve management efficiency and reduce cost in a massive scale.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/garments'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Retail} alt='Retail' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Garments</h4>
                  </div>
            </div>
          </div>
          
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Warehouse</h3>
                  <div className='mt-4'>Realize delicacy management in hospital. Guarantee medicines and medical instruments safely used. Help hospital to build a reliable and secure management system.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/warehouse'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={ware} alt='Warehouse' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Warehouse</h4>
                  </div>
            </div>
          </div>
          
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Library</h3>
                  <div className='mt-4'>Digitalized collections management saves labour, and improves work efficiency and accuracy. RFID overall solution is the best way to run a modern library.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/library'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Library} alt='Library' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Library</h4>
                  </div>
            </div>
          </div>
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Tyre Tracking</h3>
                  <div className='mt-4'>Digitalized collections management saves labour, and improves work efficiency and accuracy. RFID overall solution is the best way to run a modern library.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/tyre'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Library} alt='Library' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Tyre Tracking</h4>
                  </div>
            </div>
          </div>
     </div>
    </div> 
    
    <div className='container bg-light p-5'  >
      <div className="container-fluid position-relative row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-sm-1 p-4">
      
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Assettracking</h3>
                  <div className='mt-4'>Allow the item level retail management. It will improve management efficiency and reduce cost in a massive scale.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/assettracking'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Asset} alt='Assettracking' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Assettracking</h4>
                  </div>
            </div>
          </div>
          
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Jewellery Management</h3>
                  <div className='mt-4'>Realize delicacy management in hospital. Guarantee medicines and medical instruments safely used. Help hospital to build a reliable and secure management system.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/Jewellery'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Jewell} alt='Visitor Management' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Jewellery Management</h4>
                  </div>
            </div>
          </div>
          
          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Smart Attendance</h3>
                  <div className='mt-4'>Digitalized collections management saves labour, and improves work efficiency and accuracy. RFID overall solution is the best way to run a modern library.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/smarattendance'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Library} alt='Smart Attendance' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Smart Attendance</h4>
                  </div>
            </div>
          </div>

          <div className="col">
            <div className="sol-card card">
              <div className="sol-overlay p-1">
                <div className='sol-card-text m-4 p-2'>
                  <h3 className=''>Animal Tracking</h3>
                  <div className='mt-4'>Digitalized collections management saves labour, and improves work efficiency and accuracy. RFID overall solution is the best way to run a modern library.</div>
                </div>
                <div className='d-flex'>
                  <div className='sol-explore mt-5'><a className='sol-text' href='/solutions/animal'> Explore </a><BsArrowRight/></div>
                </div>
              </div>
               <img src={Library} alt='Smart Attendance' className='card-sol-img'/>
                  <div className="card-Sol-body container-fluid">
                    <h4 className="card-Sol-title">Animal Tracking</h4>
                  </div>
            </div>
          </div>
     </div>
    </div> 
    <ScrollButton/>
    <Footer/>
  </>
    
  )
}

export default Solutions