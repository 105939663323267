import React from 'react'
import banner from '../Images/Banner/RFID_Printer.jpg';
import {ImHome} from 'react-icons/im';
import Printer from '../Images/Products/ZD621R.png';
import Printer1 from '../Images/Products/ZD6211R.png';
import Printer2 from '../Images/Products/ZT600.png';
import Printer3 from '../Images/Products/ZT400.png';
import Footer from '../Footer';
import Z621R from '../Documents/ZD621R_ZD611R-Specification.pdf';
import ZT600R from '../Documents/ZT600R-Specification.pdf';
import ZT400R from '../Documents/ZT400R-Specification.pdf';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import ScrollButton from '../Home/Scrollbutton';
const Rfidprinter = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
   <div className='container'>
        <img src ={banner} alt="RFID-slide" className='img-fluid'/> 
         <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href ="/product">Products</Breadcrumb.Item>
            <Breadcrumb.Item active>RFID Printer</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='container'>
        <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF RFID PRINTERS</h1>
          <h5 className='mt-4'>Searching for The Right RFID Printer</h5>
        </div>
      </div>
      <div className='container'>
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1  g-3">
          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZD621R</h5>
                <img src={Printer} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={Z621R} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZD611R</h5>
                <img src={Printer1} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={Z621R} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZT600 Series</h5>
                <img src={Printer2} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={ZT600R} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ZT400 Series</h5>
                <img src={Printer3} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={ZT400R} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div> 
        <br/>
      </div> 
      <div className='container'>
        <div className='why-bg img-fluid'>
          <h1 className=' container d-inline-block text-center text-white fw-bold pt-5'>WHY CHOOSE RSPM RFID PRINTER</h1>
          <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5 ">
            <div className='col'>
              <div className='RFID-card'>
                <div className='p-4'>
                  <div className='RFID-num fw-bold '>01</div>
                  <div className='RFID-Content mt-5'>
                  <h3 className='mt-3 fw-bold'>Globally applying</h3>
                  <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='RFID-card mt-5'>
                <div className='p-4'>
                  <div className='RFID-num fw-bold '>02</div>
                  <div className='RFID-Content mt-5'>
                  <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                  <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='RFID-card'>
                <div className='p-4'>
                  <div className='RFID-num fw-bold '>03</div>
                  <div className='RFID-Content mt-5'>
                  <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                  <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div> 
    <br/>  
        
      <ScrollButton/>
      <Footer/>
    </>
  )
}

export default Rfidprinter