import React from 'react'
import banner from '../Images/Smart-Attendance.jpg';
import handled from '../Images/Products/RFD40.png';
import Printer from '../Images/Products/ZT600R.png';
import Antenna from '../Images/Products/AN440.png';
import Inlay1 from '../Images/Products/9662.png';
import AIsol from '../Images/Solution/Solution_AI.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const AI = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
      <div className='position-relative'>
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Artificial Intelligence</li>
            </ul>
          </nav>
          </div>
        </div>
      
    </div>
      <br/>
      <div className='container'>
          <h1 className='text-center mb-5 solution-head'>SOLUTION FOR ARTIFICIAL INTELLIGECE</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Artificial Intelligence:</h3>
                <p className='mt-3'>Artificial intelligence (AI) is expanding the capabilities of machine-enabled functionalities. This innovative technology facilitates machines to act with a degree of autonomy, resulting in effective execution of iterative tasks. AI enables the development of a next-generation workplace that thrives on the seamless collaboration of enterprise system and individuals. Therefore, human resources are not made obsolete, but rather, their efforts are bolstered by emerging tech. In fact, AI provides organisations with the luxury of freeing up resources for higher-level tasks.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Artificial Intelligence:</h3>
                <p className='mt-3'>AI is the ability of a machine to display human-like capabilities such as reasoning, learning, planning and creativity. and its enables technical systems to perceive their environment, deal with what they perceive, solve problems and act to achieve a specific goal.</p>
                <img src ={AIsol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Artificial Intelligence:</h3>
                <ul className='mt-3'>
                    <li>AI reduces the amount of time needed to complete a task. It makes multitasking easier and lightens the workload for existing resources.</li>
                    <li>AI enables the execution of hitherto challenging tasks without significant cost outlays.</li>
                    <li>AI operates 24x7, it never stops, and never experiences downtime.</li>
                    <li>AI improves the capabilities of differently abled individuals.</li>
                    <li>AI can be used across industries and has a large market potential..</li>
                    <li>AI makes it easier to make decisions faster and smarter.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Printer} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={handled} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
