import React from 'react'
import client1 from './Images/Clients/Client1.JPG';
import client2 from './Images/Clients/Client2.JPG';
import client3 from './Images/Clients/Client3.JPG';
import client4 from './Images/Clients/Client4.JPG';
import client5 from './Images/Clients/Client5.JPG';
import client6 from './Images/Clients/Client6.JPG';
import client7 from './Images/Clients/Client7.JPG';
import client8 from './Images/Clients/Client8.JPG';
import client9 from './Images/Clients/Client9.JPG';
import client10 from './Images/Clients/Client10.JPG';
import client11 from './Images/Clients/Client11.JPG';
import client13 from './Images/Clients/Client13.JPG';
import client14 from './Images/Clients/Client14.JPG';
import client15 from './Images/Clients/Client15.JPG';
import client16 from './Images/Clients/Client16.JPG';
import client17 from './Images/Clients/Client17.JPG';
import client18 from './Images/Clients/Client18.JPG';
import client19 from './Images/Clients/Client19.JPG';
import client20 from './Images/Clients/Client20.JPG';
import client21 from './Images/Clients/Client21.JPG';
import GovtClient1 from './Images/Clients/Govt-Client1.jpg';
import GovtClient2 from './Images/Clients/Govt-Client2.png';
import GovtClient3 from './Images/Clients/Govt-Client3.png';
import GovtClient4 from './Images/Clients/Govt-Client4.jpg';
import Zebra from './Images/zebra.png';
import Symbol from './Images/Symbol1.png';
import Marquee from 'react-fast-marquee';
import Smartrac from './Images/Smartrac.png';
import AveryDennison from './Images/AveryDennison.png';
import NXP from './Images/NXP.png';

const Partners = () => {
  return (
    <>
        <div className='container bg-light'>
          <h1 className='parter-brand border-bottom text-center  mt-2'>Our Partner Brands and clients</h1>
            <div className='container-fluid d-lg-inline-flex justify-content-center p-5'>
                      <img src={GovtClient1} alt='Zebra'  className='img-fluid m-2'/>
                      <img src={GovtClient2} alt='Zebra'  className='img-fluid m-2'/>
                      <img src={GovtClient3} alt='Zebra'  className='img-fluid m-2'/>
                      <img src={GovtClient4} alt='Zebra'  className='img-fluid m-2'/>
              </div>
          <div className='border-bottom'></div>
          <div className='container'>
            <Marquee>
            <div className='container d-flex justify-content-center m-5'>
            <img src={client1} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client2} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client3} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client4} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client5} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client6} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client7} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client8} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client9} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client10} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client11} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client13} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client14} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client15} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client16} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client17} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client18} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client19} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client20} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
                    <img src={client21} alt='Client'  className='w-100 h-80 img-fluid m-2'/>
              </div>
           
            </Marquee>
          </div>
          <div className='border-bottom'></div>
          <div className='container'>

          <div className='container d-lg-inline-flex justify-content-center  p-5'>
                      <img src={Zebra} alt='Zebra' className='img-fluid m-2'/>
                      <img src={Smartrac} alt='smartrac' className='img-fluid m-2'/>
                      <img src={Symbol} alt='Symbol' className='img-fluid m-2'/>
                      <img src={NXP} alt='NXP' className='img-fluid m-2'/>
                      <img src={AveryDennison} alt='Avery Dennison' className='img-fluid m-2'/>
              </div>
                  
          </div>
        </div>
    </>
    
   
  )
}

export default Partners