import React from 'react'
import banner from '../Images/Retailbanner.jpg';
import Reader from '../Images/Rfid-reader.jpg';
import Antenna from '../Images/Antenna.png';
import Inlay1 from '../Images/Inlay1.jpg';
import handled from '../Images/handled-reader1.jpg';
import {ImHome} from 'react-icons/im';
import ScrollButton from '../Home/Scrollbutton';
import Footer from '../Footer';

export const Retail = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="mb-0 mt-5 bg-light">
      <div className='position-relative'>
        <img src ={banner} alt="Retail-slide" className='img-fluid'/> 
        <div className='Retail-page-head mx-5'> RSPM RFID APPLICATION FOR RETAIL </div>
        <div className='Retail-page-subhead m-5'>Single Item Level Overall Solution Of Retail </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Retail</li>
            </ul>
          </nav>
        </div>
      </div>
      </div>
      <br/>
      <div className=" container-fluid row row-cols-1 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-0">
        <div className='section'>
          <div className='container flex has-sidebar'>
            <div className='richtext'>
              <h3 className='fw-bold'> Purpose of RFID Applications in Retail:</h3>
              <p className='mt-3'>RSPM hardware solution for goods receiving and dispatching allows managers to establish a high efficient process flow in stores based on big data, which can be automatically collected during the processes of receiving, shipping, etc.</p>
              <p><br/></p>
              <h3 className='fw-bold'>RFID Retail Solution:</h3>
              <p className='mt-3'>Bringing item level visibility to increase sales, reduce loss and enhance consumers' experiences in the store. RSPM's RFID retail solutions provide companies with item-level supply chain visibility from the point of manufacture to the store.</p>
              <p><br/></p>
              <h3 className='fw-bold'>Benefit of RFID Application for Retail:</h3>
              <ul className='mt-3'>
                <li>Provide a high level of automation and fast processing speed during operation.</li>
                <li>Check and identify multiple items at the same time without unpacking the boxes.</li>
                <li>Effectively simplify the process flow.</li>
                <li>Reduce human intervention and errors.</li>
              </ul>
              <p><br/></p>
            </div>
          </div>
        </div>
      </div>
      <h1 className='mx-5 fw-bold warehouse-prod-head'> RELATED RFID PRODUCTS</h1>
      <div className="container warehouse-card row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
         
            <div className="col">
                <div className="card">
                  <div className="card-body">
                    <img src={handled} alt='Rfid-Printer' className='warehouse-cared-image1 img-fluid'/>
                  </div>
                </div>
                <p className='warehouse-card-content m-2 text-center'>RFID Handheld Reader</p>
            </div>
            <div className="col">
                <div className="card">
                  <div className=" card-body">
                    <img src={Inlay1} alt='Inlay-Tag' className='warehouse-cared-image img-fluid'/>
                  </div>
                </div>
                <p className='warehouse-card-content m-2 text-center'>RFID Tag</p>
            </div>
            <div className="col">
                <div className="card">
                  <div className=" card-body">
                    <img src={Reader} alt='Rfid-Reader' className='warehouse-cared-image1 img-fluid'/>
                  </div>
                </div>
                <p className='warehouse-card-content m-2 text-center'>RFID Reader</p>
            </div>
            <div className="col">
                <div className="card">
                  <div className=" card-body">
                    <img src={Antenna} alt='Rfid-Antenna' className='warehouse-cared-image1 img-fluid'/>
                  </div>
                </div>
                <p className='warehouse-card-content m-2 text-center'>RFID Reader</p>
            </div>
         
      </div>

      <br/>
      <br/>
    <ScrollButton/>


      <Footer/>
    </>
  )
}
