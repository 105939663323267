import React from 'react'
import banner from '../Images/Banner/RFID_Readers.jpg';
import {ImHome} from 'react-icons/im';
import Reader from '../Images/Products/FX9600.png';
import Reader1 from '../Images/Products/FX7500.png';
import Reader2 from '../Images/Products/RFD40.png';
import Reader3 from '../Images/Products/FX8500.png';
import Reader4 from '../Images/Products/ART700.png';
import Reader5 from '../Images/Products/MC3300.png';
import Reader6 from '../Images/Products/ARC122.png';
import Reader7 from '../Images/Products/DesktopReader.png';
import Footer from '../Footer';
import FX9600 from '../Documents/FX9600-specification.pdf';
import FX7500 from '../Documents/FX7500-specification.pdf';
import RFD8500 from '../Documents/RFD8500-Specification.pdf';
import ART7000 from '../Documents/ART7000-Specification.pdf';
import RFD40 from '../Documents/RFD40-Specification.pdf';
import MC3300 from '../Documents/MC3300-Specification.pdf';
import ScrollButton from '../Home/Scrollbutton';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const Rfidreader = () => {
  
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    
      <div className='container'>
        <img src ={banner} alt="RFID-slide" className='img-fluid'/> 
         <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href ="/product">Products</Breadcrumb.Item>
            <Breadcrumb.Item active>RFID Reader</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='container'>
        <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF RFID READERS</h1>
          <h5 className='mt-4'>Searching for The Right RFID Reader</h5>
        </div>
      </div>
      
    <div className='container '>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 prod-card-images g-3">
          <div className='col'>
            <div className='card'>
              <div className='card-body card-prod'>
              <div className='card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold'>FX9600 Reader</div>
                <img src={Reader} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={FX9600} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">FX7500 Reader</h5>
                <img src={Reader1} alt='Rfid-Reader' className='img-fluid p-4'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={FX7500} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">RFD8500 Reader</h5>
                <img src={Reader3} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={RFD8500} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">ATR7000 RTLSReader</h5>
                <img src={Reader4} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={ART7000} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">RFD40 UHF Reader</h5>
                <img src={Reader2} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={RFD40} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">MC3300 RFID Series</h5>
                <img src={Reader5} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href={MC3300} target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Desktop Reader ACR122</h5>
                <img src={Reader6} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href='/#' target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col'>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Desktop Reader Smartconnect</h5>
                <img src={Reader7} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                  <div className='download-cat'>
                    <a href='/#' target='blank' >Download Spec</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div> 
        <br/>
    </div>
    <div className='container'>
      <div className='why-bg img-fluid'>
        <h1 className=' container d-inline-block text-center text-white fw-bold pt-5'>WHY CHOOSE RSPM RFID READER</h1>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5 ">
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>01</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Globally applying</h3>
                 <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card mt-5'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>02</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                 <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>03</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                 <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    <br/>  
      <ScrollButton/>
      <Footer/>
      
    </>
  )
}

export default Rfidreader