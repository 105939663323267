import React from 'react'
import banner from '../Images/Smart-Attendance.jpg';
import Reader from '../Images/Rfid-reader.jpg';
import Inlay1 from '../Images/Inlay1.jpg';
import handled from '../Images/handled-reader1.jpg';
import Antenna from '../Images/Antenna.png';
import Library1 from '../Images/Solution/Solution_Attendance.jpg';

import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Smartattendance = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Smart Attendance</li>
            </ul>
          </nav>
          </div>
  
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR SMART ATTENDANCE</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in Smart Attendance:</h3>
                <p className='mt-3'>RSPM A smart attendance system is a real-time solution for managing employees' day-to-day activities that can be used automatically by capturing the current date, time, and location. Smart attendance systems reduce human efforts in monitoring employee and student attendance by employing a user-friendly and automated method of attendance entry that can be recorded using an electronic register.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in Smart Attendance:</h3>
                <p className='mt-3' >RFID Smart Attendance management system keeps track of daily attendance, working hours, breaks, login, and logout time. It prevents staff's time theft. An attendance management system integrates all attendance devices such as smart cards, biometric, and facial recognition devices in real-time. Smart attendance management system records and maintains student’s attendance data using smart devices. The system collects attendance records in the system and manages, stores and processes the data. It generates daily attendance reports that help the faculty to track student’s presence with high accuracy and efficiency.</p>
                
                <img src ={Library1} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in Smart Attendance:</h3>
                <ul className='mt-3'>
                  <li>The RFID attendance recording system is accurate, fast and efficient.</li>
                  <li>Attendances are recorded and processed at a speedy basis.</li>
                  <li>Cloud Based Platform to access the data anytime anywhere.</li>
                  <li>Report generation on employee attendance.</li>
                  <li>Centralized Data Collection of multiple locations.</li>
                  <li>Increased Productivity with Reduced Overhead Costs.</li>
                  <li>Error-Free Attendance Tracking.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={Reader} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Printer</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={handled} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
