import React from 'react'
import banner from '../Images/Banner/Consumables.jpg';
import {ImHome} from 'react-icons/im';
import Label from '../Images/Products/Barcode_Label_Chromo.png';
import Label1 from '../Images/Products/Barcode_Label_Polyster.png';
import Label2 from '../Images/Products/Barcode_Label_Synthatic.png';
import Label3 from '../Images/Products/Barcode_Label_Silver_WhitePolyster.png';
import Ribbon from '../Images/Products/Wax_Ribbon.png';
import Ribbon1 from '../Images/Products/Resin_Ribbon.png';
import Ribbon2 from '../Images/Products/Premium_Wax_Ribon.png';
import Ribbon3 from '../Images/Products/Wax_Resin_Ribbon.png';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Consumables = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className='container'>
      <div className="mb-0 bg-light">
          <img src ={banner} alt="Barcode-slide" className='img-fluid'/> 
          <div className='d-flex p-1'>
            <ImHome className='m-1'/>
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
                <li className="breadcrumb-item ms-1"> <a href="/product">Products</a></li>
                <li className="breadcrumb-item active" aria-current="page">Consumables</li>
              </ul>
            </nav>
          </div>
    
      </div>
    </div>
    <div className='container'>
      <div className='Prod-head-title fw-bold mt-5'><h1>TYPES OF RFID CONSUMABLES</h1>
        <h5 className='mt-4'>Searching for The Right Consumables</h5>
      </div>
    </div> 
      <div className='container'>
      <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-3">
          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Barcode Label Chromo</h5>
                <img src={Label} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>
          
          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Barcode Label Polyster</h5>
                <img src={Label1} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Barcode Label Synthatic</h5>
                <img src={Label2} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Barcode Label Silver and WhitePolyster</h5>
                <img src={Label3} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Wax Ribbon</h5>
                <img src={Ribbon} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Resin Ribbon</h5>
                <img src={Ribbon1} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Premium Wax Ribbon</h5>
                <img src={Ribbon2} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

          <div className='col '>
            <div className='card'>
              <div className='card-body card-prod'>
              <h5 className="card-title m-1 m-sm-0 fs-2 fs-sm-5 fw-bold">Wax Resin Ribbon</h5>
                <img src={Ribbon3} alt='Rfid-Reader' className='img-fluid'/>
                <div className="overlay">
                  
                </div>
              </div>
            </div>
          </div>

      </div> 
      </div>  
        <br/>
        <br/>
        <br/>
        <div className='container'>
      <div className='why-bg img-fluid'>
        <h1 className=' container d-inline-block text-center text-white fw-bold pt-5'>WHY CHOOSE RSPM CONSUMABLES</h1>
        <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 m-5 g-5">
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>01</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Globally applying</h3>
                 <p className='mt-3'>ISO18000-6B/6C and EPC Global UHF Class 1 Gen 2.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card mt-5'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>02</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Excellent performance</h3>
                 <p className='mt-3'>High speed and accurancy in a long reading distance. Remarkable multiple reading and stable reading performance in variety of surrounding.</p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='RFID-card'>
              <div className='p-4'>
                <div className='RFID-num fw-bold '>03</div>
                <div className='RFID-Content mt-5'>
                 <h3 className='mt-3 fw-bold'>Rugged and durable</h3>
                 <p className='mt-3'>Premium design and workmanship can used in harsh environment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    <br/>  
      <ScrollButton/>
      <Footer/>
    </>
  )
}
