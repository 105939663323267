import React from 'react'
import banner from '../Images/Assettracking.png';
import Reader from '../Images/Rfid-reader.jpg';
import Inlay1 from '../Images/Inlay1.jpg';
import handled from '../Images/handled-reader1.jpg';
import Antenna from '../Images/Antenna.png';
import Filesol from '../Images/Solution/Solution_File.jpg';
import {ImHome} from 'react-icons/im';
import Footer from '../Footer';
import ScrollButton from '../Home/Scrollbutton';
export const Filetracking = () => {
  window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  return (
    <>
    <div className="container">
        <img src ={banner} alt="BWarehousebanner-slide" className='img-fluid'/> 
        <div className='continer-fluid fs-1 fs-xs-6 justify-content-center aboutpage-head'>RSPM RFID APPLICATION FOR WAREHOUSE </div>
        <div className='d-flex p-1'>
          <ImHome className='m-1'/>
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              <li className="breadcrumb-item ms-1"> <a href="/">Home</a></li>
              <li className="breadcrumb-item ms-1"> <a href="/solutions">Solutions</a></li>
              <li className="breadcrumb-item active" aria-current="page">File Tracking</li>
            </ul>
          </nav>
          </div>
  
      
    </div>
      <br/>
      <div className='container'>
      <h1 className='text-center mb-5 solution-head'>SOLUTION FOR FILE TRACKING</h1>
            <div className='container flex has-sidebar'>
              <div className='richtext'>
                <h3 className='fw-bold'> Purpose of RFID Applications in File Tracking:</h3>
                <p className='mt-3'>RFID File Tracking Systems are created and intended to reduce the amount of time office workers spend monitoring and maintaining hundreds of documents inside a working environment.</p>
                <p><br/></p>
                <h3 className='fw-bold'>Solution of RFID Technology in File Tracking:</h3>
                <p className='mt-3'>RSPM Infotech provided RFID based File Tracking solution which facilitates fast issuing, reissuing and returning of files with the help of RFID enabled modules. It directly provides the file information and member information to the file management system without any manual intervention. We provided File Tracking is a fully automated local positioning system, a system for finding, identifying and managing Files in an organization.</p>
                <img src ={Filesol} alt="" className='img-fluid mt-5'/>
                <h3 className='fw-bold'>The Benefit of RFID Application in File Tracking:</h3>
                <ul className='mt-3'>
                  <li>Automated & Effective File and Document tracking real-time tracking, searching location & availability.</li>
                  <li>Locate and retrieve files quickly and efficiently with keeping a record of the holder.</li>
                  <li>Risk of misplace or theft of document is controlled.</li>
                  <li>Eliminates authorized access to the files/ documents.</li>
                  <li>Reduction in manual cost & time loss.</li>
                  <li>Improves inventory Management.</li>
                  <li>Eliminate manual entry of same information at multiple locations.</li>
                </ul>
                <p><br/></p>
              </div>
            </div>
    </div>
      <div className='container'>
        <h1 className='mx-5 fw-bold Solution-prod-head'> RELATED RFID PRODUCTS</h1>
        <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-1 m-2 g-4">
          
              <div className="col">
                  <div className="card solution-card">
                    <div className="card-body">
                      <img src={handled} alt='Rfid-Printer' className='Solution-cared-image img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID handled Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Inlay1} alt='Inlay-Tag' className='Solution-cared-image mt-5 img-fluid '/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Tag</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Reader} alt='Rfid-Reader' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Reader</p>
              </div>
              <div className="col">
                  <div className="card solution-card">
                    <div className=" card-body">
                      <img src={Antenna} alt='RFID-Antenna' className='Solution-cared-image mt-4 img-fluid'/>
                    </div>
                  </div>
                  <p className='Solution-content m-2 text-center'>RFID Antenna</p>
              </div>
        </div>
      </div>
      <br/>
      <br/>
    <ScrollButton/>
      <Footer/>
    </>
  )
}
