import React, {useState} from 'react';
import {FaArrowCircleUp, FaWhatsapp} from 'react-icons/fa';
import ReactWhatsapp from 'react-whatsapp';

const ScrollButton = () =>{
  
const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100){
      setVisible(true)
    } 
    else if (scrolled <= 100){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <>
    <div className='scroll-btn'>
      <FaArrowCircleUp onClick={scrollToTop} 
      style={{display: visible ? 'inline' : 'none'}} />
    </div>
    <ReactWhatsapp number='+91 9940021769' message='Chat on RSPM in Whatsapp?'> <FaWhatsapp style={{display: visible ? 'inline' : 'none'}} className='whatapp-icon'/></ReactWhatsapp> 
              
   </>
  );
}
  
export default ScrollButton;