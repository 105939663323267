import React from 'react'
import {Route,Routes} from 'react-router-dom';
import logo from './Images/Logo.png';
import {BsSearch} from 'react-icons/bs'
import './navbar.css';
import './App.css';
import Home from './Home/Home';
import About from './About';
import Contactus from './Contactus';
import Solutions from './Solutions/Solutions';
import Rfidreader from './Products/Rfidreader';
import Barcodeprint from './Products/Barcodeprinter';
import { Accesscontrol } from './Products/Accesscontrol';
import { Consumables } from './Products/Consumables';
import { Warehouse } from './Solutions/Warehouse';
import { Assettracking } from './Solutions/Assettracking';
import { Jewellery } from './Solutions/Jewellery';
import { Retail } from './Solutions/Retail';  
import { Smartattendance } from './Solutions/Smartattendance';
import Products from './Products/Products';
import { Library } from './Solutions/Library';
import Garment from './Solutions/Garment';
import { Industries } from './Solutions/Industries';
import { Vehicle } from './Solutions/Vehicle';
import { Healthcare } from './Solutions/Healthcare';
import Rfidtags from './Products/Rfidtags';
import { Filetracking } from './Solutions/Filetracking';
import Rfidantenna from './Products/Rfidantenna';
import Rfidprinter from './Products/Rfidprinter';
import Biometric from './Products/Biometric';
import Barcodescanner from './Products/Barcodescan';
import Mobilecomputer from  './Products/Mobilecomputer'; 
import { Tyre } from './Solutions/Tyre';
import { Animal } from './Solutions/Animal';
import { Cylinder } from './Solutions/Cylinder';
import { AI } from './Solutions/AI';
export const Navbarnew = () => {
  return (
    <> 
        <div className="container sticky-top">
        <nav className="navbar navbar-expand-lg  shadow-box ">
       
            <a className="navbar-brand" href="/"><img src ={logo} alt="Logo" className='img fluid ms-3'/></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">HOME</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="/about">ABOUT</a>
                </li>
                <li className="nav-item dropdown ">
                <a className="nav-link dropdown-toggle" href="/product" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    PRODUCTS
                </a>
                <ul className="dropdown-menu pre-scrollable" role="menu">
                    <li><a className="dropdown-item" href="/product/Rfidreader">RFID Readers</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/Rfidantenna">RFID Antenna</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/Rfidtags">RFID Tags and Cards</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/Rfidprinter">RFID Printers</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/barcodescanner">BarCode Scanners</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/barcodeprinter">BarCode Printer</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/mobilecomputer">Mobile Computers</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/biometric">Biometric Reader</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/accesscontrol">Access Controls</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/product/consumables">Consumables</a></li>
                </ul>
                </li>
                <li className="nav-item dropdown ">
                    <a className="nav-link dropdown-toggle" href="/product" role="button" data-bs-toggle="dropdown" aria-expanded="false">SOLUTIONS</a>
                    <ul className="dropdown-menu pre-scrollable" role="menu">
                    <li><a className="dropdown-item" href="/solutions/warehouse">Warehouse Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/tyre">Tyre Tracking Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/assettracking">Asset Tracking Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/library">Library Management</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/Jewellery">Jewellery Tracking Management</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/garments">Garments Tracking Management</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/industries">Industries and Manufacturing Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/health">Healthcare Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/vehicle">Vehicle Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/file">File Tracking Solutions</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/animal">Animal Tracking Solution</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/ai">Artificial Intelligence Solution</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/cylinder">Cylinder Tracking Solution</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/solutions/smarattendance">Smart Attendance Systems</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/#">IoT Solutions</a></li>
                </ul>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="/contactus">CONTACT US</a>
                </li>
            </ul>

            <span className='search-bar me-5'><BsSearch /></span>
            </div>
          </nav>
        </div>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contactus" element={<Contactus/>} />
        <Route path="/Product/Rfidreader" element={<Rfidreader/>} />
        <Route path="/Product/Rfidantenna" element={<Rfidantenna/>} />
        <Route path="/Product/Rfidtags" element={<Rfidtags/>} />
        <Route path="/Product/Rfidprinter" element={<Rfidprinter/>} />
        <Route path="/product/barcodescanner" element={<Barcodescanner/>} />
        <Route path="/product/barcodeprinter" element={<Barcodeprint/>} />
        <Route path="/product/mobilecomputer" element={<Mobilecomputer/>} />
        <Route path="/product/biometric" element={<Biometric/>} />
        <Route path="/product/accesscontrol" element={<Accesscontrol/>} />
        <Route path="/product/consumables" element={<Consumables/>} />
        <Route path="/solutions/warehouse" element={<Warehouse/>} />
        <Route path="/solutions/industries" element={<Industries/>} />
        <Route path="/solutions/tyre" element={<Tyre/>} />
        <Route path="/solutions/vehicle" element={<Vehicle/>} />
        <Route path="/solutions/health" element={<Healthcare/>} />
        <Route path="/solutions/assettracking" element={<Assettracking/>} />
        <Route path="/solutions/library" element={<Library/>}/>
        <Route path="/solutions/garments" element={<Garment/>}/>
        <Route path="/solutions/Jewellery" element={<Jewellery/>} />
        <Route path="/solutions/retail" element={<Retail/>} />
        <Route path="/solutions/file" element={<Filetracking/>} />
        <Route path="/solutions/animal" element={<Animal/>} />
        <Route path="/solutions/cylinder" element={<Cylinder/>} />
        <Route path="/solutions/smarattendance" element={<Smartattendance/>} />
        <Route path="/solutions/ai" element={<AI/>} />
        <Route path="/product" element={<Products/>} />
        <Route path="/solutions" element={<Solutions/>} />
      </Routes>
    </>
  )
}
