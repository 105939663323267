
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Navbarnew } from './Navbarnew.js';


function App() {
  return (
    <>
    
      <BrowserRouter>
        
       <Navbarnew/>
      </BrowserRouter>
      
    </>
  );
}

export default App;
